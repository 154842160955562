import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import Title from "./Title";
// img
import imgDetail from "../images/logo.png";

const GetStarted = () => {
  const CustomGridItem = styled(Grid)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  });

  const CustomTypography = styled(Typography)({
    fontSize: "1.1rem",
    textAlign: "start",
    lineHeight: "1.5",
    color: "#515151",
    marginTop: "1.5rem",
  });

  return (
    <Grid
      container
      spacing={{ xs: 4, sm: 4, md: 0 }}
      sx={{
        py: 10,
        px: 2,
      }}
    >
      <CustomGridItem item xs={12} sm={8} md={6} component="section">
        <Box
          component="article"
          sx={{
            px: 4,
          }}
        >
          <Title text={"Community Level Sports Programs"} textAlign={"start"} />
          <CustomTypography>
            Riverbrooke Sports is not just a sports program; it's a vibrant
            community initiative that brings people together through the love of
            sports. Our program is designed to promote healthy living, teamwork,
            and camaraderie among participants of all ages and skill levels.
            Whether you're a seasoned athlete or new to the game, Riverbrooke
            Sports offers a range of activities and events tailored to suit
            everyone's interests. From friendly matches to competitive leagues,
            our goal is to create an inclusive environment where individuals can
            thrive, build lasting friendships, and experience the joy of
            sportsmanship. Through our dedication to fostering a sense of
            belonging and encouraging personal growth, Riverbrooke Sports stands
            as a beacon of unity and fun within our community. Join us in
            celebrating the spirit of sports and the power of togetherness at
            Riverbrooke Sports!
          </CustomTypography>
        </Box>
      </CustomGridItem>

      <Grid item xs={12} sm={4} md={6}>
        <img
          src={imgDetail}
          alt=""
          style={{
            width: "100%",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default GetStarted;
