import React from "react";
import ListLeagues from "../components/ListLeagues";

const Leagues = () => {
  return (
    <>
      <ListLeagues />
    </>
  );
};

export default Leagues;
