import React from "react";
import GetInTouch from "../components/GetInTouch";
import GetStarted from "../components/GetStarted";
import Header from "../components/Header";

const AboutUs = () => {
  return (
    <>
      <Header />
      <GetStarted />
      <GetInTouch />
    </>
  );
};

export default AboutUs;
