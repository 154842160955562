import React from "react";
import ListRegistrations from "../components/ListRegistrations";

const Registrations = () => {
  return (
    <>
      <ListRegistrations />
    </>
  );
};

export default Registrations;
