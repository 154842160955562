import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

import { registrationList } from "../services/RegistrationService";
import Register from "../pages/Register";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ListRegistrations = () => {
  const [registrations, setRegistrations] = useState([]);
  const currentDate = new Date();
  const futureDate = new Date(2024, 11, 31);
  useEffect(() => {
    registrationList()
      .then((response) => {
        setRegistrations(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell align="right">First Name</StyledTableCell>
            <StyledTableCell align="right">Last Name</StyledTableCell>
            <StyledTableCell align="right">Email</StyledTableCell>
            <StyledTableCell align="right">Phone Number</StyledTableCell>
            <StyledTableCell align="right">Address</StyledTableCell>
            <StyledTableCell align="right">Team Name</StyledTableCell>
            <StyledTableCell align="right">Team Members</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {registrations.map(
            (registration, counter) => (
              (counter = counter + 1),
              (
                <StyledTableRow key={registration.id}>
                  <StyledTableCell component="th" scope="row">
                    {counter}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {registration.firstName}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {registration.lastName}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {registration.email}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {registration.phoneNumber}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {registration.address}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {registration.teamName}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {registration.teamMembers}
                  </StyledTableCell>
                </StyledTableRow>
              )
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ListRegistrations;
