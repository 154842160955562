import React from "react";
import EventInfo from "../pages/EventInfo";

const Home = () => {
  return (
    <>
      <EventInfo />
    </>
  );
};

export default Home;
