import React, { Component } from "react";
//rotas
import { Routes, Route, HashRouter } from "react-router-dom";
//pages
import Home from "./pages/Home";
import Leagues from "./pages/Leagues";
import Events from "./pages/Events";
import PhotoGallery from "./pages/PhotoGallery";
import Register from "./pages/Register";
import Contact from "./pages/Contact";
import AboutUs from "./pages/AboutUs";
import EventInfo from "./pages/EventInfo";
import Thankyou from "./pages/Thankyou";
import Registrations from "./pages/Registrations";

//componentes
import Navbar from "./components/Navbar";
import axios from "axios";

export default class App extends Component {
  render() {
    return (
      <>
        <HashRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Leagues" element={<Leagues />} />
            <Route path="/Events" element={<Events />} />
            <Route path="/PhotoGallery" element={<PhotoGallery />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/EventInfo" element={<EventInfo />} />
            <Route path="/Thankyou" element={<Thankyou />} />
            <Route path="/Registrations" element={<Registrations />} />
          </Routes>
        </HashRouter>
      </>
    );
  }
}
