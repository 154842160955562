import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const EventInfo = () => {
  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    // tamanhos
    gap: theme.spacing(2),
    paddingTop: theme.spacing(10),
    // cor de fundo
    backgroundColor: "#3498db",
    background: "linear-gradient(to right bottom, white, white)",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const BoxText = styled(Box)(({ theme }) => ({
    flex: "1",
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      flex: "2",
      textAlign: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <CustomBox component="header">
      {/*  Box text  */}
      <BoxText component="section">
        <Typography
          variant="h5"
          component="h1"
          align="center"
          sx={{
            fontWeight: 200,
            color: "#3498db",
            fontFamily: "cursive",
            align: "center",
          }}
        >
          Community Spirit, Endless Fun: Riverbrooke Sports - Where Community
          and Sports Collide!
        </Typography>
        <Typography
          component="h1"
          sx={{
            fontFamily: "sans-serif",
            align: "center",
          }}
        >
          <b>
            <h2 align="center">🏀 Basketball Event 🏀</h2>
          </b>
          <br></br>
          <Box sx={{ textAlign: "left", m: 1 }}>
            🏀🎉 Get Ready to Hoop It Up! 🎉🏀
            <br></br>
            <br></br>Dear Riverbrooke Residents,
            <br></br>
            <br></br>Get ready to dribble like a pro because we're excited to
            announce the most epic event to kick off the summer - The
            Riverbrooke Basketball Tournament! 🙌🎉
            <br></br>Whether you're a seasoned baller with a killer crossover or
            just looking to have some fun and maybe score a few buckets or don’t
            want to play but love to referee, this tournament is for YOU! We're
            welcoming all residents to lace up their sneakers, gather your tribe
            of 5 or more, and hit the court for a day of friendly competition
            and community spirit.
            <br></br>
            <br></br> Here's the scoop:
            <br></br>🏀 **What**: Riverbrooke Basketball Tournament
            <br></br>📅 **When**: Saturday July 27th at 9 AM
            <br></br>📍 **Where**: Riverbrooke Basketball courts
            <br></br>
            <br></br>How to sign up!
            <br></br>Please register here:{" "}
            <b>
              <Link to="/Register">Register</Link>
            </b>
            <br></br>So, what are you waiting for? Dust off your lucky jersey,
            practice your victory dance, and get ready for a slam dunk of a time
            at the Riverbrooke Basketball Tournament!
            <br></br>See you on the court!
            <br></br>Sincerely,
            <br></br>
            <b>Riverbrooke Sports Committee</b>
            <br></br>
            <br></br>
            <br></br>
            <b>Rules Recap:</b>
            <br></br>
            <ul>
              <li>Teams of 5 or more players</li>{" "}
              <li>No one under the age of 9 </li>
              <li>
                <b>MUST</b> be a resident (owner or renter) of our Riverbrooke
                community
                <b>(NO GUESTS)</b>
              </li>{" "}
              <li>
                You can still register if you do not have a team. We will help
                in making a team.
              </li>{" "}
              <li>Waivers have to be signed before starting to play</li>{" "}
            </ul>
            <br></br>
            <br></br>
            <br></br>
            <b>
              Calling all Riverbrooke resident Entrepreneurs and Business
              owners:
            </b>{" "}
            <br></br>
            Be part of our community's excitement by sponsoring our upcoming
            basketball tournament and get your business seen by passionate fans
            and players alike! <br></br>
            <br></br>Please reach out to <b>riverbrookesports@gmail.com</b> and
            let us know how you can help.
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </Box>
        </Typography>
      </BoxText>
    </CustomBox>
  );
};

export default EventInfo;
