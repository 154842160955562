import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const FormPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    team: "",
    teamMembers: "",
  });
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await fetch(
          //"http://localhost:8080/ctgopenapi/v1/rbsmember",
          "http://ctgopenapi.us-east-1.elasticbeanstalk.com/ctgopenapi/v1/rbsmember",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
        setErrorMessage("");
        setErrors("");
        console.log(response.status);
        if (response.ok) {
          alert("Form submitted successfully!");
          navigate("/Thankyou");
        } else {
          const result = response.json();
          //setErrorMessage(result);
          setErrorMessage("This Email Id is already registered.");
          //throw new Error("Failed to submit form");
        }
      } catch (error) {
        //console.error("Error submitting form:", error);
        console.error("Error:", error.response.data);
        //alert("Failed to submit form. Please try again.");
      }
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.firstName.trim()) {
      errors.firstName = "First Name is required";
    }
    if (!data.lastName.trim()) {
      errors.lastName = "Last Name is required";
    }
    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Invalid email format";
    }
    if (!data.address.trim()) {
      errors.address = "Address is required";
    }
    return errors;
  };

  const isValidEmail = (email) => {
    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Box sx={{ maxWidth: 500, mx: "auto", mt: 5 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Register
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <TextField
          label="First Name"
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          fullWidth
          error={!!errors.firstName}
          helperText={errors.firstName}
          margin="normal"
        />
        <TextField
          label="Last Name"
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          fullWidth
          error={!!errors.lastName}
          helperText={errors.lastName}
          margin="normal"
        />
        <TextField
          label="Email"
          id="email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          error={!!errors.email}
          helperText={errors.email}
          margin="normal"
        />
        <TextField
          label="Phone Number"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Address"
          id="address"
          name="address"
          multiline
          rows={4}
          value={formData.address}
          onChange={handleChange}
          fullWidth
          error={!!errors.address}
          helperText={errors.address}
          margin="normal"
        />
        <TextField
          label="Team Name"
          id="teamName"
          name="teamName"
          value={formData.teamName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Team Members"
          id="teamMembers"
          multiline
          rows={4}
          name="teamMembers"
          value={formData.teamMembers}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <FormHelperText>
          Please enter the names of all team members, separated by commas.
        </FormHelperText>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          sx={{ mt: 3 }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default FormPage;
